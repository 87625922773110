<template>
    <div>
        <div class="header">
            <div class="title">Digersånger</div>
        </div>
        <div class="wrap-all">
                <div v-for="shanty in shanties" v-bind:key="shanty.file">
                    <router-link :to="'/shanty/' + shanty.file + '/0'" class="shanty-link">
                        <div>{{shanty.name}}</div>
                        <Right/>
                    </router-link>
                </div>
        </div>
    </div>    
</template>

<script>
// import ShantyList from '@/assets/shanties/available-shanties.json';
import Right from 'vue-material-design-icons/ChevronRight.vue';
export default {
    components: {
        Right
    },
    data: function () {
        return {
            shanties: [
                {"file": "ShantyAmI", "name": "Shanty Am I (Santiana)"},
                {"file": "Stråssa", "name": "Stråssa (Wellerman)"},
                {"file": "PortDire", "name": "Port Dire (Old Maui)"},
                {"file": "HemMotDigerhamn", "name": "Hem Mot Digerhamn (Mingulay Boat Song)"}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .wrap-all {
        border-top: 1px solid #00f6ff;
    }
    .header {
        padding: 1.5rem;
        .title {
            font-size: 1.5rem;
            font-weight: 300;
        }
    }
    .shanty-link {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #13002d;
        color: inherit;
        text-decoration: none;
        border-bottom: 1px solid #00f6ff;
    }
    .shanty-link-wrap:hover {
        background: #370087;
        border-bottom: 1px solid #00f6ff;
    }
</style>