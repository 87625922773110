<template>
    <div class="settings-wrap">
        <div class="header">
            <div class="title">Settings</div>
        </div>
        <div class="reset-buttons">
            <input type="button" class="reset-btn" value="CLEAR LAST VIEWED SHANTIES" @click="clearLastViewed">
            <input type="button" class="reset-btn" value="DELETE ALL BOOKMARKS" @click="deleteBookmarks">
        </div>
    </div>    
</template>

<script>
export default {
    methods: {
        clearLastViewed: function() {
            this.$store.commit('removeLastViewed');
        },
        deleteBookmarks: function() {
            this.$store.commit('removeAllBookmarks');
        }
    }
}
</script>

<style lang="scss" scoped>
.settings-wrap {
    padding: 0 1.5rem;
    .header {
        padding: 1.5rem 0 0 0;
        .title {
            font-size: 1.5rem;
            font-weight: 300;
        }
    }
    .reset-buttons {
        padding: 1rem 0;
        .reset-btn {
            margin: 1rem 0;
            padding: .5rem 0;
            width: 100%;
            text-align: center;
            border: 1px solid #e74c3c;
            color: #e74c3c;
            background: none;
            border-radius: 4px;
            background: none;
        }
        .reset-btn:hover {
            outline: none;
            background: rgba(231, 76, 60, 0.3);
        }
        .reset-btn:active {
            outline: none;
            background: rgba(231, 76, 60, 0.5);
        }
    }
}
</style>